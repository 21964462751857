import { BuysRepository } from '../../domain/models/buy/gateway/buys-repository';
import HttpClient from '../helpers/http-client';
import { Observable } from 'rxjs';

export default class BuysRepositoryImp extends BuysRepository {
  create(uid: string, cid: string | null, data: any): Observable<any> {
    const httpClient = new HttpClient(uid, cid);
    return httpClient.post(`/buy`, data);
  }
}
