import CarritoRepositoryImp from "../../../infrastructure/carrito/carrito-implementation";
import { dispatchCustomEvent } from "../../../utils/dispatch";

const carritoRepo = CarritoRepositoryImp.getInstance();

function addItemToCar(event) {
    var auxCar = getCar();
    var itemExist = false;
    var itemAux = event.detail.data;
    var types = carritoRepo.getTypes();
    var type = carritoRepo.getType();

    auxCar.forEach((item) => {
        if (item.auxsku === `${itemAux.sku}-tmp`) {
            itemExist = true;
            itemAux = item;
            return;
        }
    });

    if (itemExist) {
        itemAux.amount += 1;
        itemAux.total = parseFloat(itemAux.value) * parseFloat(itemAux.amount);
        itemAux["auxtax"] =  itemAux.total - (itemAux.total / (1 + parseFloat(itemAux[types[type]])))
        carritoRepo.update(itemAux.sku, "amount", itemAux.amount);
    } else {
        itemAux["auxsku"] = itemAux.sku+"-tmp";
        itemAux["amount"] = 1;
        itemAux["total"] = parseFloat(itemAux.value) * parseFloat(itemAux.amount);
        itemAux["auxtax"] =  itemAux["total"] - (itemAux["total"] / (1 + parseFloat(itemAux[types[type]])))
        carritoRepo.add(itemAux);
    }

    dispatch()
}

function cloneItem(event) {

    var types = carritoRepo.getTypes();
    var type = carritoRepo.getType();

    var itemAux = { ...event.detail.data };
    let cnt = prompt("Precio:", itemAux["value"])
    itemAux["auxsku"] = itemAux.sku+"-tmp-"+Math.floor(Math.random() * 16777215).toString(16);
    itemAux["value"] = parseFloat(cnt);
    itemAux["amount"] = 1;
    itemAux["total"] = parseFloat(itemAux.value) * parseFloat(itemAux.amount);
    itemAux["auxtax"] =  itemAux["total"] - (itemAux["total"] / (1 + parseFloat(itemAux[types[type]])))
    carritoRepo.add(itemAux);
    dispatch()
}

function removeItemsFromCar(event) {
    carritoRepo.remove(event);
}

function clearCar() {
    carritoRepo.clear();
    dispatch()
    dispatchCustomEvent("update-show-pos-pay", {});
    dispatchCustomEvent("btn-thirds-add", undefined);
}

function getItemsAmount() {
    var amount = 0
    getCar().map((item) => {
        amount += item.amount
    })
    return amount
}

function getCar() {
    return carritoRepo.get();
}

function updateItem(auxsku, key, value) {
    carritoRepo.update(auxsku, key, value);
    dispatch()
}

function getTaxGroup() {
    return carritoRepo.getTaxes();
}

function getTotal() {
    return carritoRepo.getTotal();
}

function dispatch() {
    dispatchCustomEvent("update-sellmodule", { car: getCar(), tax: getTaxGroup(), total: getTotal() });
    dispatchCustomEvent("update-paymodule", { car: getCar(), tax: getTaxGroup(), total: getTotal() });
    dispatchCustomEvent("update-badge-pos-pay", { badge: getItemsAmount() });
}

function setType(item) {
    return carritoRepo.setType(item);
}

export { addItemToCar, cloneItem, removeItemsFromCar, clearCar, getCar, updateItem, getTaxGroup, getTotal, setType };
