//Custo css import
import './style.css'
import SellModule from '../../organismos/sellModule/view';
import ThirdsModal from '../../thirds/organismos/thirdsModal/view';
import ProductsTable from '../../products/organismos/productsTable/view';

//React import
import { useEffect, useState } from 'react';

//Classes
import { userSingleton } from "../../classes/User"
import PayMethods from '../../payment/organismos/paymethods/view';
import GeneralLayout from '../../templates/general/view';
import ButtonGroup from '../../moleculas/buttonGroup/view';
import { ButtonBasic } from '../../atomos/buttons';

function Compras(props) {

    const [terceros, setTerceros] = useState([]);
    const [showPay, setShowPay] = useState(false);
    const [badge, setBadge] = useState(0);
    const screenWidth = window.innerWidth;
    const [terceroaux, SetTerceroAux] = useState(null);

    useEffect(() => {
        /* if (!userSingleton.uid) {
            window.location.replace('/login')
        } */
        window.addEventListener('update-badge-pos-pay', (e) => {setBadge(e.detail.data.badge)})
        return () => {
            window.removeEventListener('update-badge-pos-pay', (e) => {setBadge(e.detail.data.badge)})
        }
    }, [])

    useEffect(() => { setTerceros(terceros) }, [terceros])

    return (
        <GeneralLayout>
            <div className='compras'>
                <ButtonGroup>
                    <ThirdsModal
                        id="btn-group-third"
                        tercero={terceroaux}
                        setTerceros={setTerceros}
                        SetTerceroAux={SetTerceroAux}
                        type="provider"
                    ></ThirdsModal>
                    <ButtonBasic hidden={screenWidth > 600} badge={badge} onClick={() => { setShowPay(!showPay) }}>Pagar</ButtonBasic>
                </ButtonGroup>
                <div className='pos-box'>
                    <div className='pos-stock'>
                        <ProductsTable
                            width={screenWidth > 600 ? "100%" : "95vw"}
                            tableHeight={screenWidth > 600 ? "63vh" : "65vh"}
                            forceLoadData={true}
                            hidenBuyValue={true}
                            compras={true}
                            buy={true}
                        ></ProductsTable>
                    </div>
                    <div hidden={window.innerWidth > 600 ? false : !showPay} className='pos-pay'>
                        <SellModule
                            terceros={terceros}
                            tercero={terceroaux}
                            type="buy"
                        >
                            <PayMethods
                                tercero={terceroaux}
                                handleCleanTercero={SetTerceroAux}
                                type="buy"
                            >
                                <ThirdsModal
                                    id="web-third"
                                    tercero={terceroaux}
                                    setTerceros={setTerceros}
                                    SetTerceroAux={SetTerceroAux}
                                    type="provider"
                                ></ThirdsModal>
                            </PayMethods>
                        </SellModule>
                    </div>
                </div>
            </div>
        </GeneralLayout>
    )

}

export default Compras
