//Custo css import
import './style.css'

//Boostrap import
import { BsFillCartCheckFill } from 'react-icons/bs';
import { FaClone } from 'react-icons/fa';
import Form from 'react-bootstrap/Form';

import { EmptyArray } from '../../../domain/models/empty/empty'

//React import
import { useEffect, useState } from 'react';

import Table from '../../../moleculas/tablas/view';
import ButtonAction from '../../../atomos/buttons/buttonAction/view';
import ProductsRepositoryImp from '../../../infrastructure/products/products-repository-implementation'
import { userSingleton } from '../../../classes/User';
import NewProduct from '../newProduct/view';
import EditProduct from '../editProduct/view';
import BarcodeScanner from '../../../atomos/barcodescanner/barcodescanner';

function ProductsTable(props) {

    const productRepository = new ProductsRepositoryImp();

    const [inventarioFull, SetInventarioFull] = useState([]);
    const [inventario, SetInventario] = useState([]);


    useEffect(() => {
        getProduct()
        window.addEventListener('update-stock', handleUpdate);
        return () => {
            window.removeEventListener('update-stock', handleUpdate);
        };
    }, []);

    function getProduct() {
        const productSuscription = productRepository.get(userSingleton.uid, userSingleton.cid).subscribe(
            (data) => {
                if (data?.length == 0) {
                    SetInventarioFull(EmptyArray)
                    SetInventario(EmptyArray)
                } else {
                    SetInventarioFull(data)
                    SetInventario(data)
                }
            },
            (error) => {
                console.error(error)
                SetInventario(undefined)
            }
        )
        return () => productSuscription.unsuscribe()
    }

    function handleUpdate(event) {
        if (event.defaultPrevented) {
            return;
        }
        event.preventDefault();
        getProduct()
    }

    function findProduct(input, data) {
        console.log(data, input)
        var response = data.filter((item) => {
            var tmp = item.name.toLowerCase().includes(input.toLowerCase())
            if (!tmp) {
                tmp = item.sku.toLowerCase().includes(input.toLowerCase())
            }
            if (!tmp) {
                tmp = item.barcode?.toLowerCase().includes(input.toLowerCase())
            }

            return tmp
        })
        return response
    }

    function handleSearchBar(event) {
        var input = event.target.value
        if (input === "") {
            SetInventario(inventarioFull)
        } else {
            var aux = findProduct(input, inventarioFull)
            SetInventario(aux)
            if (aux.length === 0) {
                SetInventario(EmptyArray)
            }
        }
    }

    return (
        <div>
            <BarcodeScanner data={inventarioFull} id='products'></BarcodeScanner>
            <div ref={props.ref} className='search-box'>
                <Form.Control
                    id='search-bar-products'
                    placeholder='Busca productos por sku o nombre'
                    onChange={(e) => {handleSearchBar(e)}}
                    list='lista'
                ></Form.Control>
                <datalist className='vd-datalist' id='lista'>
                    {
                        inventario?.map((item, idx) => {
                            return (
                                <option key={idx} value={item.name}>{item?.name}</option>
                            )
                        })
                    }
                </datalist>
            </div>
            <Table
                width={props.width ? props.width : "90vw"}
                emptyMessage="Aun no tienes productos"
                emptyChild={<NewProduct></NewProduct>}
                maxHeight={props.tableHeight ? props.tableHeight : "35vh"}
                headersList={
                    props.hidenBuyValue ?
                        ["sku", "Nombre", "Valor Venta", "accion"] : ["sku", "Nombre", "Valor venta", "Valor compra", "accion"]}
                data={inventario ? inventario : []}
                excludeList={
                    props.hidenBuyValue ?
                        ["users_id", "buy_tax","barcode", "id", "sell_tax", "sell_taxes_id", "stock", "skutmp", "buy_value", "buy_taxes_id", "auxsku", "total", "amount", "auxtax"] :
                        ["users_id", "buy_tax","barcode", "id", "sell_tax", "sell_taxes_id", "stock", "skutmp", "auxsku", "total", "amount", "buy_taxes_id", "auxtax"]
                }
            >
                <ButtonAction hidden={props.hiddenAction ? props.hiddenAction : false} btnKey="add-product" className='action-btn'>
                    <BsFillCartCheckFill></BsFillCartCheckFill>
                </ButtonAction>
                <ButtonAction hidden={props.hiddenAction ? props.hiddenAction : false} btnKey="clone-product" className='action-btn'>
                    <FaClone></FaClone>
                </ButtonAction>
                <EditProduct hidden={props.enableEdit ? !props.enableEdit : true} btnKey="inventario-edit-item" className='action-btn'></EditProduct>
            </Table>
        </div>
    )
}


export default ProductsTable;
