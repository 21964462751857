import { ProductRepository } from '../../domain/models/products/gateway/products-repository';
import { PayMethods, Product } from '../../domain/models/products/products';
import HttpClient from '../helpers/http-client';
import { Observable } from 'rxjs';

export default class ProductsRepositoryImp extends ProductRepository {
  get(uid: string, cid: string | null): Observable<Product[]> {
    const httpClient = new HttpClient(uid, cid);
    return httpClient.get<Product[]>(`product`);
  }

  create(uid: string, cid: string | null, body: Product): Observable<Product> {
    const httpClient = new HttpClient(uid, cid);
    return httpClient.post<Product>(`product`, body);
  }

  edit(uid: string, cid: string | null, id: string, body: Product): Observable<Product> {
    const httpClient = new HttpClient(uid, cid);
    return httpClient.put<Product>(`product?sku=${id}`, body);
  }

  delete(uid: string, cid: string | null, id: string): Observable<Product> {
    const httpClient = new HttpClient(uid, cid);
    return httpClient.delete<Product>(`product?sku=${id}`);
  }

  getPayMethods(uid: string, cid: string | null): Observable<PayMethods[]> {
    const httpClient = new HttpClient(uid, cid);
    return httpClient.get<PayMethods[]>(`v1/paymethods`);
  }

  createFromFile(uid: string, cid: string | null, body: FormData): Observable<any> {
    const httpClient = new HttpClient(uid, cid);
    return httpClient.post<any>(`product/upload`, body);
  }
}
