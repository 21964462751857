import { userSingleton } from "../../../classes/User";
import SellsRepositoryImp from '../../../infrastructure/sells/sells-repository-implementation';
import { dispatchCustomEvent } from "../../../utils/dispatch";

const repo = new SellsRepositoryImp();

function createSell(data) {
    const suscription = repo.create(userSingleton.uid, userSingleton.cid, data).subscribe(
        (data) => {
            dispatchCustomEvent("notification-popup", {message: "Venta exitosa"})
            dispatchCustomEvent("process-payment", {bill: data})
        },
        (error) => {
            dispatchCustomEvent("notification-popup", {message: "Error en la venta", type:"error"})
            dispatchCustomEvent("process-payment", {bill: error})
        }
    )
    return () => suscription.unsubscribe()
}

export { createSell }
