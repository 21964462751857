//Custo css import
import './style.css'
import AccountingReports from '../../organismos/accountingReports/view';
import PlanCuentas from '../../organismos/planCuentas/view';
import Parameterization from '../../organismos/parameterization/view';
import { useUserAuth } from "../../../AuthContext";
import Button from 'react-bootstrap/Button';
import { AiOutlineLogin } from 'react-icons/ai';
import { useEffect, useState } from 'react';
import { userSingleton } from "../../classes/User"
import { deleteCookie } from '../../utils/helper';
import { MdPostAdd, MdSell } from "react-icons/md";

import Account from '../../organismos/account/view';
import GeneralLayout from '../../templates/general/view';
import ProductsHub from '../../products/organismos/productsHub/view';
import { ButtonHub } from '../../atomos/buttons';
import { FaFileInvoice, FaSellcast } from 'react-icons/fa';

function AppHub(props) {
    const { logOut } = useUserAuth();
    const [profileData, SetProfileData] = useState(null);

    useEffect(() => {
        if (!userSingleton.uid) {
            window.location.replace('/login')
        }
    }, [])

    const receiveDataFromChild = (data) => {
        SetProfileData(data);
    };

    function replace(url) {
        window.location.replace(url)
    }

    return (
        <GeneralLayout>
            <div className='grid-container'>
                <Account profileData={profileData} SetProfileData={receiveDataFromChild}></Account>
                <ButtonHub width="95vw" id="pos" onClick={() => {replace("/pos")}} title="Pos" icon={<MdSell/>}></ButtonHub>
                <ButtonHub width="95vw" id="compras" onClick={() => {replace("/compras")}} title="Compras" icon={<MdPostAdd/>}></ButtonHub>
                <ButtonHub width="95vw" id="invoice" onClick={() => {replace("/invoice")}} title="Facturas" icon={<FaFileInvoice/>}></ButtonHub>
                <ProductsHub btn={true}></ProductsHub>
                <AccountingReports></AccountingReports>
                <Parameterization></Parameterization>
                <PlanCuentas></PlanCuentas>
                <ButtonHub width="95vw" id="invoice" onClick={() => [logOut(), deleteCookie("data"), window.location.replace('/login')]} title={<p style={{ color: "rgb(238,110,158)" }}>Salir</p>} icon={<AiOutlineLogin style={{ color: "rgb(238,110,158)" }}/>}></ButtonHub>

            </div>
        </GeneralLayout>
    )

}

export default AppHub
