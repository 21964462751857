//Custo css import
import { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import './style.css'

function LoadingBtn(props) {

    const [loading, SetLoading] = useState(false)

    useEffect(()=>{
        SetLoading(props.loading)
    }, [props.loading])

    return (
        <div className='loading-box'>
            {
                loading === true && (
                    <ReactLoading
                        className='loading-btn'
                        color='rgba(49, 151, 149, 1)'
                    />)
            }
            {
                loading === false && (props.children)
            }
        </div>
    )
}

export default LoadingBtn
