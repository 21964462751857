//Custo css import
import './style.css'

//Controller import
import { locationState } from './controller'

//React import
import { useEffect, useState } from 'react'
import { useUserAuth } from "../../../AuthContext";

//Classes
import { userSingleton } from "../../classes/User"
import ModalBtn from '../../moleculas/modalButton/hub/view';
import { Button } from 'react-bootstrap';
import UserRepositoryImp from '../../infrastructure/user/user-repository-implementation'
import Table from '../../moleculas/tablas/view';
import { ButtonAction } from '../../atomos/buttons';
import { BsPersonFillCheck } from 'react-icons/bs';

function TopBar(props) {

    const userRepository = new UserRepositoryImp();

    const [profileData, SetProfileData] = useState(NaN)
    const [shared, SetSharedProfiles] = useState([])
    const [state, SetState] = useState('pos')

    const { logOut } = useUserAuth();

    useEffect(() => {
        SetState(locationState())
        loadProfile()
    }, [])

    useEffect(() => {
        window.addEventListener('btn-top-bar-impersonate', impersonate);
        window.addEventListener('update-top-bar', loadProfile);
        window.addEventListener('logout', logOut);
        return () => {
            window.removeEventListener('btn-top-bar-impersonate', impersonate);
            window.removeEventListener('update-top-bar', loadProfile);
            window.removeEventListener('logout', logOut);
        };
    }, []);

    function loadProfile() {
        const userSuscription = userRepository.get(userSingleton.uid, userSingleton.cid).subscribe(
            (data) => {
                SetProfileData(data)
            },
            (error) => {
                console.error("Error loading profile: ", error)
            }
        )
        return () => {
            userSuscription.unsubscribe()
        }
    }

    function impersonate(event) {
        userSingleton.impersonate(event.detail.data.uid, event.detail.data.roles_id).then(
            (res) => {
                SetProfileData(res)
                window.location.reload()
        })
    }

    function handleSharedProfiles() {
        const sharedProfilesSuscription = userRepository.getSharedProfiles(userSingleton.uid, userSingleton.uid).subscribe(
            (data) => {
                SetSharedProfiles(data)
            },
            (error) => {
                console.error("Error loading shared profile: ", error)
            }
        )
        return () => sharedProfilesSuscription.unsubscribe()
    }

    return (
        <header className='topbar'>
            <h1># EasyPos</h1>
            <div style={{ display: "flex" }}>
                <ModalBtn
                    id="topbar"
                    type="topbar"
                    onClick={handleSharedProfiles}
                    btnstyle={{ width: "auto", textAlign: "center", color: "white", backgroundColor: "rgb(0, 170, 170)", fontSize: "25px" }}
                    title={profileData.companyName ? profileData.companyName : ''}>
                    <div className="linked-companies"
                >
                    <Table
                        headersList={["Empresa", "Role", "Acceder"]}
                        excludeList={["uid", "roles_id"]}
                        data={shared}
                    >
                        <ButtonAction btnKey="top-bar-impersonate">
                            <BsPersonFillCheck></BsPersonFillCheck>
                        </ButtonAction>
                    </Table>
                    </div>
                </ModalBtn>
                <nav className='menu' style={{ height: "100%", width: "90px" }}>
                    <li>
                        <img className='tb-img' style={{ position: "absolute", top: "0px" }} src={profileData.avatar ? profileData.avatar : "https://www.shutterstock.com/image-vector/avatar-vector-male-profile-gray-260nw-538707355.jpg"}></img>
                    </li>
                </nav>
            </div>
        </header>
    )

}

export default TopBar
